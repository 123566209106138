import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
 .marketing{
  display: flex;
  flex-direction: row;
  min-height: 100%;
 }
 .marketing-image{
  width: 100%;
  overflow:hidden;
  position: relative;
 }

 :global(.dialog-backdrop){
    background: #151515 !important
 }
 .stv-logo{
  display: block;
  width: 190.99px;
  height: 62.64px;
  margin: 42px 0px 0px 42px;
 }
 :global(button) {
  width: 244px !important;
  height: 41px;
  font-weight: 400 !important;
  border-radius: 8px !important;
  border: none;
  padding: 9px 32px 9px 32px !important;

  
 }
 .marketing-logo{
  width: 125.5px;
  height: 125.48px;
  displat: block;
 }
 .marketing-image > img{
  min-height: 100%;
  object-fit: cover;
 }
 .first-section{
  width: 100%;
 }
 .ps-heading{
  padding : 8px 0px 20px 0px;
  font-family: Source Sans Pro;
  font-size: 48px;
  font-weight: 600;
  line-height: 64.04px;
  text-align: left;
  color: ${colors.primaryText};
 }
 span{
  background: linear-gradient(152.29deg, #938DFE 51.83%, #8C42E1 93.35%, #6135D8 118.83%, #4302C2 165.09%);
  font-family: Source Sans Pro;
  font-size: 48px !important;
  font-weight: 600;
  line-height: 64.04px;
  text-align: left;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
 }
 .subtitle{
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 600;
  line-height: 34.56px;
  text-align: left;
  color: ${colors.primaryText};
 }
.wrap-content{
  width: 600px;
  padding-top: 40px;
  margin: 0 auto;
}
ul{
  margin: 34px 0px 26px 0px;
}
 li{
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.63px;
  text-align: left;
  padding-bottom: 8px;
  color: ${colors.primaryText};
 }
 .footer {
  color: ${colors.secondaryText};
  font-size: 16px;
  font-weight: 400;
  line-height: 20.11px;
  text-align: left; 
  bottom: 30px;
  width: inherit;
}

.footer p {
  margin-bottom: 1rem;
}

.link {
  color:${colors.textHoverPurple};
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.sub-text{
font-family: Source Sans Pro;
font-size: 18px;
font-weight: 400;
line-height: 22.63px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: ${colors.primaryText}

 }
 .button-wrapper{
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 500px;
  padding: 24px 0px;
 }
  .success-modal{
    background-color: ${colors.baseBg};
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 18px;
  }
  
  .success-modal-inner{
    margin: auto;
    height: 100%;
    display: block;
    width: 100%;
  }
  
  .success-modal-contents {
    position: static;
    margin: auto;
    height: 100%;
  }
  

  #success-terms {
    text-align: left;
  }

  #success-header{
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 46px;
      line-height: 50px; 
      display: flex;
      align-items: center;
      text-align: center;     
      background: linear-gradient(81.24deg, #F544C6 29.81%, #FBA217 51.05%, #79CC32 74.63%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
  }
  

  @media (max-width: 1023px){
    .marketing{
      display: flex;
      flex-direction: column-reverse;
      height: auto;
     }
      .marketing-image {
        position: absolute;
        top: 0;
      }
     .marketing-image::after {
      content: '';
      background: linear-gradient(180deg, rgba(21, 21, 21, 0) -1.83%, rgba(21, 21, 21, 0.7) 31.87%, #151515 73.07%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
      height: 150px;
     }

     .wrap-content{
    width: auto !important;
    position: absolute;
    z-index: 99;
    padding: 0px 60px;
    top: 500px;
    margin: 0 auto;
     }
     .footer {
      position: unset;
     }
     .marketing-image > img{
      width: inherit;
     }
     .stv-logo{
      display: none !important;
     }
     .button-wrapper{
      width: 500px !important;
     }
  }
    
  @media (max-width: 1250px){
    .ps-heading{
      font-size: 36px;
    }
    span{
      font-size: 36px !important;
    }
    ul{
      margin: 24px 0px 26px 0px;
    }
    .wrap-content{
      width: 480px;
      padding-top: 20px;
       }  
    .stv-logo{
        display: block;
        width: 150px;
        height: 50px;
        margin: 32px 0px 0px 32px;
        }
       .button-wrapper{
        width: 450px;
       }
  }
 
  @media (max-width: 767px) {
    .marketing-logo{
        display: none;
     }
     .marketing-image > img{
        height: 350px;
     }
    .wrap-content{
      width: 100%;
      padding: 0px 16px;
      top: 280px;
      }
.button-wrapper{
  flex-direction:column;
  width: 100% !important; 
  gap: 8px;

}
:global(button) {
  width: 100% !important;
}
li{
  font-size: 14px;
}
.subtitle{
  font-size: 16px;
  text-align: center;
  font-weight: 510;
  width: 320px;
  line-height: 19.09px;
  margin: 0 auto;
}
.ps-heading{
font-size: 28px;
text-align: center;
line-height: 35.2px;
padding-bottom: 16px;
}
span{
  font-size: 28px !important;
  text-align: center;
  line-height: 35.2px;
}
.sub-text{
  font-size: 16px; 
}
ul{
  margin: 24px 0px;
}
.footer{
font-family: Source Sans Pro;
font-size: 12px;
font-weight: 400;
line-height: 15.08px;
}
  }
`
