import css from 'styled-jsx/css'

export default css`
  .avatar-button {
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 600;
    background: linear-gradient(152.62deg, #938DFE -3.78%, #8C42E1 63.04%, #6135D8 105.22%, #4302C2 164.08%);
    border: none;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 50%;
    text-transform: capitalize;
    position: relative;
    vertical-align: middle;
    font-size: inherit;
  }

  .avatar-button.plus {
    background: linear-gradient(105.34deg, #81EC20 3.25%, #00E5DF 46.46%, #948FFF 83.4%, #14CC7D 123.48%);
  }
  
  .avatar-button.premier_sports {
    background: linear-gradient(125.86deg, #F0950D -7.01%, #FFFF00 74.17%);
  }

  .avatar-button.premier_sports span {
    color: black;
  }

  .avatar-button span {
    position: absolute;
    color: #212121;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .avatar-button .label{
    position: absolute;
    color:white;
    background:#212121;
    padding: 4px 8px 4px 8px;
    min-width: 50px;
    height: auto;
    border-radius: 32px;
    bottom: -8px;
}

p.text{
  font-size: 14px;
  font-weight: 600;
  position: relative;
  text-align: center;
  font-family: Source Sans Pro;
  line-height: 17.81px;
}
  .avatar-button.summary {
    font-size: 64px;
  }

  a.disabled {
    cursor: unset;
  }

`
