/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import qs from 'qs'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import cookies from 'js-cookie'
import debounce from 'lodash/debounce'
import styles from './styles'
import { getCurrentDomain, getEnvironment } from '../../modules/utils'
import loginService from '../../initLoginService'
import Button from '../../components/Button'
import setPageName from '../../utils/pageName'
import Modal from '../../components/Modal'
import marketinglogo from './marketinglogo.png'
import stvlogo from './STV_Player_Logo.png'
import { trackEvent } from '../../modules/dtm-helpers'

const domain = getCurrentDomain()
const env = getEnvironment(domain)
const myDomain = env === 'development' ? 'stvqa.tv' : domain
const VipPage = (props) => {
  const [state, setState] = useState({
    error: null,
    selectedNewsletter: [],
    optedInEmail: false,
  })
  const [marketingImage, setMarketingImage] = useState('https://s3.eu-west-1.amazonaws.com/images.stvqa.tv/background_images/web_marketing.png')

  const subscription = useSelector(reduxState => reduxState.user.subscription?.info)
  const history = useHistory()

  const isPlayerUser = subscription && (subscription.status === 'active' || subscription.isTrial)
  useEffect(() => {
    if (subscription) {
      if (props.newUser) {
        isPlayerUser ? setPageName({
          page: 'onboarding',
          guid: 'vipsubp+',
        }) : setPageName({
          page: 'onboarding',
          guid: 'vipsub',
        })
      } else {
        isPlayerUser ? setPageName({
          page: 'login',
          guid: 'vipsubp+',
        }) : setPageName({
          page: 'login',
          guid: 'vipsub',
        })
      }
    }
  }, [isPlayerUser, props.newUser, subscription])

  const updateLayout = () => {
    if (window.innerWidth < 500) {
      setMarketingImage('https://s3.eu-west-1.amazonaws.com/images.stvqa.tv/background_images/mobile_marketing.png')
    } else if (window.innerWidth < 1024) {
      setMarketingImage('https://s3.eu-west-1.amazonaws.com/images.stvqa.tv/background_images/tab_marketing.png')
    } else {
      setMarketingImage('https://s3.eu-west-1.amazonaws.com/images.stvqa.tv/background_images/web_marketing.png')
    }
  }

  useEffect(() => {
    updateLayout()
    window.addEventListener('resize', debounce(updateLayout, 300))
    return () => {
      window.removeEventListener('resize', debounce(updateLayout, 300))
    }
  }, [])

  const onHandleSignIn = (e) => {
    e.preventDefault()
    const isMarketing = (e.target.id === 'signIn')
    const selectedNewsletter = state.selectedNewsletter || '{}'
    const newsLetters = {
      ...selectedNewsletter,
      STV_OptedInEmail: isMarketing,
    }
    window.digitalData.stv.vip_status = isMarketing ? 1 : 0
    if (e.target.id === 'signIn') {
      setState({
      }, trackEvent('VIPCountMeIn', {}))
    } else if (e.target.id === 'notNow') {
      setState({
      }, trackEvent('VIPNotNow', {}))
    }
    return loginService.updateUserDataMarketing(newsLetters)
      .then((response) => {
        setState({
          error: null, optedInEmail: newsLetters.STV_OptedInEmail,
        })
        cookies.set('STV_VIP_OPTIN', newsLetters.STV_OptedInEmail, { expires: 365, domain: getCurrentDomain() })
        redirect()
      })
      .catch((error) => {
        setState({ error: 'Sorry, something went wrong, please try again.' })
        redirect()
      })
  }

  const redirect = () => {
    const {
      siteAction,
      source,
      returnAfterSignIn,
    } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (siteAction && siteAction === 'remoteLogin') {
      if (source) {
        window.location.href = decodeURIComponent(source)
        return
      }
      if (Number(returnAfterSignIn)) {
        window.history.back()
        return
      }
    }
    if (siteAction && siteAction === 'localLogin') {
      if (source) {
        history.push(decodeURIComponent(source))
        return
      }
    }
    window.location.assign(`https://player.${myDomain}`)
  }
  return (
    <Modal>
      {() => (
        <div className="success-modal">
          <div className="success-modal-inner">
            <div className="success-modal-contents">
              <div className="marketing">
                <div className="first-section">
                  <img className="stv-logo" src={stvlogo} alt="stv player logo" />
                  <div className="wrap-content">
                    <img className="marketing-logo" src={marketinglogo} alt="marketinglogo" />
                    <div className="ps-heading">Don’t miss all the<span> good stuff!</span></div>
                    <div className="subtitle">Sign up to receive emails about STV services which can include:</div>
                    <ul>
                      <li>Updates on your favourite shows</li>
                      <li>Personalised recommendations based on your viewer preferences</li>
                      <li>Carefully curated promotions and competitions</li>
                    </ul>
                    <div className="sub-text">You can opt out at any time.</div>

                    <div className="button-wrapper">
                      <Button
                        id="signIn"
                        type="button"
                        className="marketing-button"
                        onClick={onHandleSignIn}
                        onKeyPress={onHandleSignIn}
                      >
                        Keep me up to date
                      </Button>
                      <Button
                        id="notNow"
                        type="button"
                        variant="primary"
                        className="marketing-button"
                        onClick={onHandleSignIn}
                        onKeyPress={onHandleSignIn}
                      >
                        Maybe later
                      </Button>
                    </div>
                    <div className="footer">
                      <p>
                        Emails are personalised based on the shows you've watched, your use of services and the information you have provided.
                        For more information about how and why STV collects and uses your information, see our{' '}
                        <a href="#" className="link">privacy policy</a>.
                      </p>
                      <p>
                        You can update your marketing and privacy settings at any time through your account settings or by clicking the unsubscribe link at the foot of our emails.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="marketing-image">
                  <img src={marketingImage} alt="second half section" />
                </div>
              </div>
            </div>
          </div>
          <style jsx>{styles}</style>
        </div>
      )}
    </Modal>
  )
}

VipPage.propTypes = {
  user: PropTypes.shape({
    birthdate: PropTypes.string,
    'cognito:username': PropTypes.string.isRequired,
  }),
  newUser: PropTypes.bool,
}

VipPage.defaultProps = {
  user: {
    newsletters: {
      STV_OptedInEmail: null,
    },
    birthdate: '1/1/1999',
    'cognito:username': '',
  },
  newUser: false,
}

export default compose(reduxForm({
  form: 'VipPage',
  destroyOnUnmount: true,
}))(VipPage)
