import { SET_PS_ENABLED } from '../constants/action_types'
import { isPsEnabled } from '../utils/featureFlags'

const initialState = {
  psEnabled: isPsEnabled(),
}

export default function featureFlags(state = initialState, action) {
  switch (action.type) {
    case SET_PS_ENABLED:
      return {
        ...state,
        psEnabled: action.payload,
      }
    default:
      return state
  }
}
