import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Script from 'react-load-script'
import { connect } from 'react-redux'
import {
  getCurrentDomain,
  getEnvironment,
} from '../../modules/utils'
import { initializeFeatureFlags } from '../../actions/featureFlagsActions'
import { resetCSS } from '../../styles'
import loginService from '../../initLoginService'
// eslint-disable-next-line no-unused-vars
// import { trackEvent } from '../../modules/dtm-helpers'

class App extends Component {
  constructor() {
    super()
    this.state = {
      authenticationChecked: false,
      scriptLoaded: false,
    }
  }

  componentDidMount() {
    this.props.initializeFeatureFlags()
    this.checkAuthenticatedUser()
  }

  handleScriptLoad = () => {
    this.setState({ scriptLoaded: true })
    this.checkAuthenticatedUser()
  }

  timeOutRequest = () => {
    setTimeout(() => {
      this.handleScriptLoad()
    }, 2000
    )
  }

  checkAuthenticatedUser = () => {
    loginService.getUser()
      .then((user) => {
        if (user) {
          this.props.setUser(user)
        }
        this.setState({
          authenticationChecked: true,
        })
        // trackEvent('directPageView', {})
      })
    const userObject = loginService.getCognitoUserObject()

    if (!userObject) return null

    return loginService.getUserSession(userObject)
      .then(session => (
        loginService.refreshUserSession({
          user: userObject,
          session,
        })
      ))
      .catch(() => {})
  }

  render() {
    const { authenticationChecked, scriptLoaded } = this.state
    const showApp = authenticationChecked && scriptLoaded
    const scriptSrc = getEnvironment(getCurrentDomain()) === 'production'
      ? 'https://assets.adobedtm.com/980fdca8755e/d12278723161/launch-d34751ee7f9d.min.js'
      : 'https://assets.adobedtm.com/980fdca8755e/d12278723161/launch-9246ea9215be-development.min.js'

    const oneTrustScriptSrc = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
    const oneTrustDataDomain = getEnvironment(getCurrentDomain()) === 'production'
      ? '501f8365-e90b-4927-930a-fe9e88c80148'
      : '9d88eda3-f1fc-48ca-93a7-f07871e6ec67-test'
    const { children } = this.props
    return (
      <Fragment>
        <Script
          id="dtmScript"
          url={scriptSrc}
          onLoad={this.handleScriptLoad}
          onError={this.handleScriptLoad}
          onCreate={this.timeOutRequest}
        />

        <Script
          id="oneTrustScript"
          url={oneTrustScriptSrc}
          attributes={{
            'data-domain-script': oneTrustDataDomain,
            src: oneTrustScriptSrc,
          }}
        />
        <Script id="stripe" url="https://js.stripe.com/v3/" />
        { showApp && (
          <div className="App">
            {children}
            <style jsx global>{resetCSS}</style>
          </div>
        )}
      </Fragment>
    )
  }
}

App.propTypes = {
  children: PropTypes.shape({}).isRequired,
  setUser: PropTypes.func.isRequired,
  initializeFeatureFlags: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  initializeFeatureFlags,
}

export default connect(null, mapDispatchToProps)(App)
